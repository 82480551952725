import React from 'react';
import dayjs from 'dayjs';

const AwardItem = ({ honor, date, organizer, notes }) => {
    const year = dayjs(date).format('YYYY');
    return (
        <div className='w-full gap-y-4'>
            <span className='block uppercase font-semibold'>{honor}</span>
            <span className='block'>{year}</span>
            <span className={`block ${notes && 'mb-1'}`}>{organizer}</span>
            {notes && <span className='block italic text-sm'>*{notes}</span>}
        </div>
    );
}

export default AwardItem;