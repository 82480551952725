import React from 'react';
import { graphql } from "gatsby";

import AwardsPage from 'modules/awards/components/awards-page';

const AwardsPageWrapper = ({ data: { markdownRemark: { frontmatter: { awardsIntro } }, allPagesJson: { nodes } } }) => {
  const awards = nodes[0].award.map(({ honor, category, organizer, date, notes }) => ({ honor, category, organizer, date, notes }));
  return (
    <AwardsPage spiel={awardsIntro} awards={awards} />
  );
}

export const pageQuery = graphql`
  query AwardsQuery {
    allPagesJson {
      nodes {
        award {
          category
          date
          honor
          notes
          organizer
        }
      }
    }
    markdownRemark(fileAbsolutePath: {glob: "**/pages/awards.md"}) {
      frontmatter {
        awardsIntro
      }
    }
  }
`;

export default AwardsPageWrapper;
