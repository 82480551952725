import React from 'react'

import Layout from "modules/common/components/layout";
import SEO from "modules/common/components/seo";
import AwardItem from './award-item';

const AwardsPage = ({ awards, spiel }) => {
    return (
        <Layout>
            <SEO
                keywords={[`awards`, 'honors', 'Professional Keystone Press Awards', 'City University of New York and Langeloth Foundation', 'IRE', 'Society of Professional Journalists']}
                title="Awards"
            />
            <section className={`w-full flex justify-center`}>
                <div className="max-w-4xl mx-4 my-8">
                    <h1 className=" font-bold text-3xl mb-3">
                        Awards
                    </h1>
                    <p className="mb-8 sm:text-lg">{spiel}</p>
                    <div className='w-full grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-6'>
                        {
                            awards.map(({ honor, date, organizer, notes }) => (
                                <AwardItem
                                    key={honor + organizer + date}
                                    honor={honor}
                                    date={date}
                                    organizer={organizer}
                                    notes={notes}
                                />
                            ))
                        }
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default AwardsPage;